import React from "react";
import imageUrl from "../utils/ImageUrl";

function Facilities() {
  const data1 = [
    {
      title: "Medical Allowance",
      image: "salary.png",
    },
    {
      title: "Leave travel allowance",
      image: "salary.png",
    },
    {
      title: "Conveyance Allowance",
      image: "salary.png",
    },
    {
      title: "Annual paid leaves",
      image: "salary.png",
    },
  ];
  const data2 = [
    {
      title: "Semi Annual increments",
      image: "salary.png",
    },
    {
      title: "Leave encashment",
      image: "salary.png",
    },
    {
      title: "Performance Based bonuses",
      image: "salary.png",
    },
    {
      title: "Over time/compensation hours",
      image: "salary.png",
    },
  ];
  return (
    <section id="facilities">
      <div className="container">
        <div className="sec-padding">
          <div className="main">
            <div class="parallax-title text-center text-capitalize">
              <h2>Facilities & Benefits</h2>
              <span class="underline w-20" style={{ width: "20%" }}></span>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="benifits">
                  {data1.map((item, index) => (
                    <div className="inner-benifits my-4 d-flex" key={index}>
                      <img src={imageUrl(item.image)} alt={item.image} />
                      <h4>{item.title} </h4>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-6">
                {data2.map((item, index) => (
                  <div className="inner-benifits my-4 d-flex" key={index}>
                    <img src={imageUrl(item.image)} alt={item.image} />
                    <h4>{item.title} </h4>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Facilities;

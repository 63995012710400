export const MobileData = {
  firstRow: [
    {
      imageUrl: "apple-icon.png",
      title: "IOS",
    },
    {
      imageUrl: "android-icon.png",
      title: "Android",
    },
    {
      imageUrl: "flutter-icon.png",
      title: "Flutter",
    },
    {
      imageUrl: "react-native-icon.png",
      title: "React Native",
    },
  ],
  secondRow: [
    {
      imageUrl: "ionic-icon.png",
      title: "Ionic",
    },
    {
      imageUrl: "kotlin-icon.png",
      title: "Kotlin",
    },
    {
      imageUrl: "swift-icon.png",
      title: "Swift",
    },
  ],
};
export const frontendData = {
  firstRow: [
    {
      imageUrl: "Html.png",
      title: "HTML 5",
    },
    {
      imageUrl: "css.png",
      title: "CSS",
    },
    {
      imageUrl: "java.png",
      title: "JavaScript",
    },
    {
      imageUrl: "typescript.png",
      title: "TypeScript",
    },
  ],
  secondRow: [
    {
      imageUrl: "react.png",
      title: "Reactjs",
    },
    {
      imageUrl: "angular.png",
      title: "Angular",
    },
    {
      imageUrl: "vuejs.png",
      title: "Vue.js",
    },
  ],
};

export const dataBaseData = {
  firstRow: [
    {
      imageUrl: "firebase.png",
      title: "Firebase",
    },
    {
      imageUrl: "oracle.png",
      title: "Oracle",
    },
    {
      imageUrl: "mongodb.png",
      title: "MongoDB",
    },
    {
      imageUrl: "redis.png",
      title: "Redis",
    },
  ],
  secondRow: [
    {
      imageUrl: "mysql.png",
      title: "MySQL",
    },
    {
      imageUrl: "sql-server.png",
      title: "SqlServer",
    },
  ],
};
export const backendData = {
  firstRow: [
    {
      imageUrl: "nodejs.png",
      title: "Nodejs",
    },
    {
      imageUrl: "php.png",
      title: "PHP",
    },
    {
      imageUrl: "dotnet.png",
      title: "Dotnet",
    },
    {
      imageUrl: "django.png",
      title: "Django",
    },
  ],
  secondRow: [
    {
      imageUrl: "java.png",
      title: "Java",
    },
    {
      imageUrl: "typescript.png",
      title: "TypeScript",
    },
  ],
};

export const cmsData = {
  firstRow: [
    {
      imageUrl: "wordpress.png",
      title: "WordPress",
    },
    {
      imageUrl: "drupal.png",
      title: "Drupal",
    },
    {
      imageUrl: "codeigniter.png",
      title: "CodeIgniter",
    },
    {
      imageUrl: "magento.png",
      title: "Magento",
    },
  ],
  secondRow: [
    {
      imageUrl: "prestaShopp.png",
      title: "PrestaShop",
    },
  ],
};

export const allPortfolioData = {
  firstRow: [
    {
      imageUrl: "apple-icon.png",
      title: "dataBase",
    },
    {
      imageUrl: "android-icon.png",
      title: "Areeb",
    },
    {
      imageUrl: "flutter-icon.png",
      title: "Flutter",
    },
    {
      imageUrl: "react-native-icon.png",
      title: "React Native",
    },
  ],
  secondRow: [
    {
      imageUrl: "ionic-icon.png",
      title: "Ionic",
    },
    {
      imageUrl: "kotlin-icon.png",
      title: "Kotlin",
    },
    {
      imageUrl: "swift-icon.png",
      title: "Swift",
    },
  ],
};

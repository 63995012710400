import React from "react";
import { Link } from "react-router-dom";
import { Header } from "./index";

const CareerBanner = ({ page, title, para }) => {
  return (
    <div className="home-section">
      <Header />
      <div>
        <div className="container">
          <div className="sec-padding">
            <div className="row">
              <div className="col-lg-12 d-flex  flex-column align-items-start justify-content-end ">
                {/* <div className="heading">
                  <h4>{page}</h4>
                </div> */}
                <div className="title-home">
                  <h1>{title}</h1>
                  {para}
                  <hr className="line" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerBanner;

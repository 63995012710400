import React from "react";
import { Parallax } from "react-scroll-parallax";
import {
  Footer,
  Header,
  PortfolioList,
  CareerBanner,
} from "../../components/index";
// import { Link } from "react-router-dom";
// import { CareerBanner } from "../career/Career";
// import { Zoom, Slide, Fade } from "react-reveal";
// import "./oluary.scss";
// import { Cloud1, Cloud2, Cloud3, Oluary1 } from "../../assets/images/index";

function Portfolio() {
  return (
    <>
      <div id="custom-web" className="for-parallax-footer">
        {/* =========================banner============ */}
        {/* <section className="banner oluary-banner barter-exchange-banner  ">
          <Header />
          <div className="main">
            <div className="container ">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="main-cloud">
                    <div className="first-cloud">
                      <img src={Cloud1} alt="cloud.png" className="mt-5" />
                    </div>
                    <div className="third-cloud">
                      <img src={Cloud3} alt="cloud.png" />
                    </div>
                    <div className="second-cloud">
                      <img src={Cloud2} alt="cloud.png" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-5 col-md-12 col-sm-12">
                  <Fade left>
                    <div className="banner-content mt-5">
                      <div className="title-home">
                        <h2>Our Portfolio Projects</h2>
                        <hr
                          className="line"
                          style={{
                            borderTop: "6px solid #F89200",
                            width: "50%",
                          }}
                        />
                        <h4>+52 the high-quality Projects Delivered</h4>
                      </div>
                    </div>
                  </Fade>
                </div>
                <div className="col-lg-7 col-md-12 col-sm-12">
                  <div className="banner-img">
                    <img src={Oluary1} alt="Oluary.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <CareerBanner page="Portfolio" title="Our Portfolio" />
        <PortfolioList />
        {/* =============================section-portfolio========================== */}
        {/* <section id="home-portfolio" className={`home-portfolio`}>
          <div className="main">
            <div className="banner-container">
              <div className="sec-padding">
                <div className="main-portfolio">
                  <div className="row">
                    <div className="col-md-3 col-sm-3 col-xsm-12 d-flex flex-column justify-content-evenly">
                      <div className="tabbing-link">
                        <div className="heading">
                          <h1>
                            Our <br /> Featured <br /> Portfolio
                          </h1>
                          <span className="underline"></span>
                        </div>
                        <div className="links">
                          <ul>
                            <li>
                              <a href="#">All</a>
                            </li>
                            <li>
                              <a href="#">Static Website</a>
                            </li>
                            <li>
                              <a href="#">Responsive Website</a>
                            </li>
                            <li>
                              <Link to="/custom-web">Custom Website</Link>
                            </li>
                            <li>
                              <a href="#">Game</a>
                            </li>
                            <li>
                              <a href="#">Video</a>
                            </li>
                            <li>
                              <a href="#">Logo Design</a>
                            </li>
                            <li>
                              <a href="#">Character Design</a>
                            </li>
                            <li>
                              <Link to="/case-study">Case Studies</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-9 col-xsm-12">
                      <div className="tabbing-img">
                        <div className="row gx-0">
                          <div className="col-md-5 m-0 p-0">
                            <div className={`tabbing-card`}>
                              <div className="content">
                                <h3>Island Girl</h3>
                                <button className="custom-btn ">
                                  View Case Study
                                </button>
                              </div>
                              <img
                                src={lap1}
                                alt="portfolio-images"
                                className="w-100"
                              />
                            </div>
                          </div>
                          <div className="col-md-5  m-0 p-0">
                            <div className={`tabbing-card`}>
                              <div className="content">
                                <h3>Island Girl</h3>
                                <button className="custom-btn ">
                                  View Case Study
                                </button>
                              </div>
                              <img
                                src={lap2}
                                alt="portfolio-images"
                                className="w-100"
                              />
                            </div>
                          </div>
                          <div className="col-md-2 m-0 p-0">
                            <div className={`tabbing-card `}>
                              <div className="small-content content">
                                <h3>Island Girl</h3>
                                <button className="custom-btn ">
                                  View Case Study
                                </button>
                              </div>
                              <img
                                src={pluton}
                                alt="portfolio-images"
                                className="w-100 height-img"
                              />
                            </div>
                          </div>
                          <div className="col-md-2 m-0 p-0">
                            <div className={`tabbing-card`}>
                              <div className="small-content content">
                                <h3>Island Girl</h3>
                                <button className="custom-btn ">
                                  View Case Study
                                </button>
                              </div>
                              <img
                                src={character}
                                alt="portfolio-images"
                                className="w-100 height-img"
                              />
                            </div>
                          </div>
                          <div className="col-md-5 m-0 p-0">
                            <div className={`tabbing-card `}>
                              <div className="content">
                                <h3>Island Girl</h3>
                                <button className="custom-btn ">
                                  View Case Study
                                </button>
                              </div>
                              <img
                                src={lap3}
                                alt="portfolio-images"
                                className="w-100"
                              />
                            </div>
                          </div>
                          <div className="col-md-5 m-0 p-0">
                            <div className={`tabbing-card`}>
                              <div className="content">
                                <h3>Island Girl</h3>
                                <button className="custom-btn ">
                                  View Case Study
                                </button>
                              </div>
                              <img
                                src={lap4}
                                alt="portfolio-images"
                                className="w-100"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="more-work-btn d-flex">
                        <button className="btn btn-primary ">
                          <a href="#">More work</a>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* ===========================footer================ */}
      </div>
      <Parallax translateY={[-20, 20]}>
        <Footer />
      </Parallax>
    </>
  );
}

export default Portfolio;

import React from "react";
import {
  Footer,
  Header,
  TechniCalities,
  TechWeWork,
  YearsOfWork,
} from "../../../components";
// import { CareerBanner } from "../career/Career";
import { Zoom, Fade, Slide } from "react-reveal";
import { Parallax } from "react-scroll-parallax";
import "./island-girl.scss";
import {
  GameLogic,
  Islandgirl1,
  Islandgirl2,
  Islandgirl3,
  Islandgirl4,
  Islandgirl5,
  Islandgirl6,
  Islandgirl7,
  islandGirlBgLayer,
} from "../../../assets/images/index";

function CaseStudy() {
  return (
    <>
      <div id="custom-web" className="for-parallax-footer">
        {/* =========================banner============ */}
        <section className="banner">
          {/* <img
            src={islandGirlBgLayer}
            alt=""
            style={{
              zIndex: "-2",
              position: "absolute",
              height: "inherit",
              width: "100%",
            }}
          /> */}
          <Header />
          <div className="banner-container">
            <Fade left>
              <div className="banner-content mt-5">
                <div className="title-home">
                  <h2>
                    A classic side-scrolling runner game <br /> played with NFTs
                  </h2>
                  <hr
                    className="line"
                    style={{ borderTop: "3px solid #198754" }}
                  />
                </div>
              </div>
            </Fade>{" "}
            <div>
              <div className="banner-img">
                {" "}
                <img src={Islandgirl1} alt="islandgirl.png" />{" "}
              </div>
            </div>
          </div>
        </section>
        <section id="case-study-content" className="clearfix">
          <div className="container case-container">
            <div className="sec-padding">
              <div className="row mt-5">
                <div className="col-lg-12 d-flex  flex-column align-items-start justify-content-end ">
                  <Fade up cascade>
                    <div className="content">
                      <div className="heading">
                        <h1>
                          Island Girl is an NFT game based on a P2E model based
                          on blockchain technology.
                        </h1>
                        <span className="underline"></span>
                      </div>

                      <div className="para">
                        <p>
                          Pluton’s establishment was laid in 2018. Our
                          experience started determined to utilize
                          state-of-the-art innovation to <br />
                          carry transparency and trustworthiness to organization
                          processes.
                        </p>{" "}
                      </div>
                    </div>
                  </Fade>
                </div>
                <div className="col-lg-12 mt-2 d-flex  flex-column align-items-center justify-content-end ">
                  <div className="banner-img">
                    <Fade right>
                      <img src={Islandgirl2} alt="Island girl image" />
                    </Fade>
                  </div>
                </div>
              </div>
              <div className="row flex-column-reverse">
                <div className="col-lg-12 d-flex  flex-column align-items-start justify-content-end ">
                  <Fade up cascade>
                    <div className="content">
                      <div className="heading">
                        <h1>Island Girl Game Idea</h1>
                        <span className="underline"></span>
                      </div>

                      <div className="para">
                        <p>
                          Island Girl Runner is a classic side-scrolling runner
                          wherein game users will proceed with their game
                          character (a Island Girl NFT product) picking up coins
                          (to earn rewards) on their way past monsters and
                          obstacles as they advance along a map.
                        </p>{" "}
                        <p>
                          Users will also grab power-ups that enhance their
                          earning power that can save their lives. This game is
                          based on a P2E model, and players will earn as they
                          play a fun-filled and motivating game. Island Girl is
                          a game envisioned for both, the Android and iOS, and
                          is part of a wider plan to extend action into the
                          metaverse.
                        </p>
                        <p>
                          Island Girl was in search of a capable developer to
                          build its game to perfection.
                        </p>
                      </div>
                    </div>
                  </Fade>
                </div>
                <div className="col-lg-12 mt-2 d-flex  flex-column align-items-center justify-content-end ">
                  <div className="banner-img">
                    <Fade left cascade>
                      <img src={Islandgirl3} alt="Island girl image" />
                    </Fade>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 d-flex  flex-column align-items-start justify-content-end ">
                  <Fade up cascade>
                    <div className="content">
                      <div className="heading">
                        <h1>Island Girl Game Development Partner</h1>
                        <span className="underline"></span>
                      </div>

                      <div className="para">
                        <p>
                          Based on its Binance Smart Chain, Island Girl’ wanted
                          a partner capable of building this game for a
                          competitive market comprising play-to-earn games. And
                          with many of the stakeholders and product strategists
                          at Pluton being P2E game enthusiasts themselves, this
                          said a lot about their expertise and ability to
                          critique games as a preferred choice as a development
                          partner.
                        </p>{" "}
                        <p>
                          Island Girl needed to have this game built by an
                          expert with a track record of working with blockchain
                          and NFT technologies. After reviewing dozens of
                          companies, Island Girl believed Pluton was the partner
                          fully capable of building a premium game developer
                          with a professional approach.
                        </p>
                        <p>
                          Island Girl and Pluton were both pleased to announce
                          their partnership. According to Island Girl, Pluton
                          was naturally a good fit for this project because of
                          its expertise in blockchain development.
                        </p>
                      </div>
                    </div>
                  </Fade>
                </div>
                <div className="col-lg-12 mt-2 d-flex  flex-column align-items-center justify-content-end ">
                  <div className="banner-img">
                    <Fade left>
                      {" "}
                      <img
                        src={Islandgirl4}
                        alt="Island girl image"
                        className="island-girl-4"
                      />
                    </Fade>
                  </div>
                </div>
              </div>
              <div className="row flex-column-reverse">
                <div className="col-lg-12 d-flex  flex-column align-items-start justify-content-end ">
                  <Fade up cascade>
                    <div className="content">
                      <div className="heading">
                        <h1>Development & Launch</h1>
                        <span className="underline"></span>
                      </div>

                      <div className="para">
                        <p>
                          There are several game development companies engaged
                          in building the next best game and with mobile gaming
                          being a growing industry that has millions riveted
                          playing one game or another, the quicker the product
                          is launched, the better it would. Among these are P2E
                          games or play-to-earn games that allow you to spend
                          time building up revenue as you play.
                        </p>{" "}
                        <p>
                          Island Girl’ game development is complete, and the
                          game has been launched according to their scheduled
                          launch date.
                        </p>
                        <p>
                          It’s worth mentioning that a farming system is also
                          being developed alongside the mobile game. Even more
                          significant than this is the team’s plan to expand the
                          concept into the Metaverse during 2022, which would
                          give Island Girl massive potential.
                        </p>
                      </div>
                    </div>
                  </Fade>
                </div>
                <div className="col-lg-12 mt-2 d-flex  flex-column align-items-center justify-content-end ">
                  <div className="banner-img">
                    <Fade right>
                      <img src={Islandgirl5} alt="Island girl image" />
                    </Fade>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 d-flex  flex-column align-items-start justify-content-end ">
                  <div className="banner-img">
                    <Fade right>
                      <img src={Islandgirl6} alt="Island girl image" />
                    </Fade>
                  </div>
                </div>
                <div className="col-lg-12 mt-2 d-flex  flex-column align-items-center justify-content-end ">
                  <div className="banner-img">
                    <Fade left>
                      <img src={Islandgirl7} alt="Island girl image" />
                    </Fade>
                  </div>
                </div>
              </div>
              <TechniCalities />
              <div className="row">
                <div className="col-lg-12 d-flex  flex-column align-items-start justify-content-end ">
                  <Fade up cascade>
                    <div className="content">
                      <div className="heading">
                        <h1>NFTs</h1>
                        <span className="underline"></span>
                      </div>

                      <div className="para">
                        <p>
                          Amazon, Beach, Norse gods, Pirate, sorcerer, and
                          space.
                        </p>{" "}
                      </div>
                    </div>
                  </Fade>
                </div>
                <div className="col-lg-12 d-flex  flex-column align-items-start justify-content-end ">
                  <Fade up cascade>
                    <div className="content">
                      <div className="heading">
                        <h1>Marketplace</h1>
                        <span className="underline"></span>
                      </div>

                      <div className="para">
                        <p>
                          Welcome to The marketplace! Here, you can see all of
                          the loveable Island Girl that are currently for sale
                          by other users. Interested in putting one of your
                          MicroPets up for sale? Head on over to your Inventory.
                          Please note there is a 2% fee paid on all transactions
                          in Island Girl tokens at time of listing.
                        </p>{" "}
                      </div>
                    </div>
                  </Fade>
                </div>
                <div className="col-lg-12 d-flex  flex-column align-items-start justify-content-end ">
                  <Fade up cascade>
                    <div className="content">
                      <div className="heading">
                        <h1>Token</h1>
                        <span className="underline"></span>
                      </div>

                      <div className="para">
                        <p>
                          Island Girl leverages the BEP-1155 NFT standard, which
                          enables numerous benefits for its users. One of those
                          core benefits is the ability for users to buy multiple
                          crates with a single transaction fee and then pay just
                          one additional transaction fee to open all of them at
                          once.
                        </p>{" "}
                        <p>
                          How does this add more value for the Island Girl
                          token? Well, the easier it is for users to buy more
                          crates at once the more volume will be sent through
                          the Island Girl App, further driving higher price
                          action and utility. From a marketplace perspective,
                          users can even buy and sell NFT's in stacks of their
                          choosing!
                        </p>
                      </div>
                    </div>
                  </Fade>
                </div>
                <div className="col-lg-12 d-flex  flex-column align-items-start justify-content-end ">
                  <Fade up cascade>
                    <div className="content">
                      <div className="heading">
                        <h1>Tokenomics</h1>
                        <span className="underline"></span>
                      </div>

                      <div className="para">
                        <p>
                          Island Girl combines the power of reasonable buy and
                          sell taxes for the BSC space! Having portions of each
                          transaction added to liquidity ensures less price
                          volatility whereas the manual buyback is used to
                          create bullish momentum or defend key support areas.
                          The game’s marketing tax ensures not only a continuous
                          stream of new investors, but necessary funding.
                        </p>{" "}
                        <p>
                          Users buy NFT from Island Girl marketplace, and then
                          they will be playing the game while connecting their
                          wallet. The core concept of the game revolves around
                          the idea of creating a fun and engaging "Play to Earn"
                          2D endless running game where anyone can earn coins
                          through skilled gameplay and contribution to the
                          ecosystem. Successful players who complete the game
                          will be rewarded with the collected coins directly to
                          their wallets.
                        </p>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ===========================tech-we-wrok======================================= */}
        <TechWeWork />

        {/* ===========================years-of-working======================================= */}
        <YearsOfWork />
        {/* ===========================footer================ */}
      </div>
      {/* <Footer /> */}
      <Parallax translateY={[-20, 20]}>
        <Footer />
      </Parallax>
    </>
  );
}

export default CaseStudy;

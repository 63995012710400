import axios from "axios";


const createBackendServer = (baseURL) => {
    const api = axios.create({
        baseURL,
        headers: {Accept: "application/json"},
        timeout: 60 * 1000,
    });

    const headers = {
        "Content-Type": "multipart/form-data",
    };


    const careerFormApi = async (body) => await api.post('/api/form/career', body, {headers});

    //Returning all the API
    return {
        careerFormApi
    };
};

const apis = createBackendServer('http://localhost:5000');
// const apis = createBackendServer('https://plutonbackend.herokuapp.com/');

export default apis;

import React, {useState} from "react";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

function Dropzone({ open, setFile }) {
    const { getRootProps, getInputProps, acceptedFiles } =
        useDropzone(
            {
                multiple: false,
                accept: ".pdf, .jpg. jpeg",
                onDrop: (acceptedFiles) => {
                    setFile((prevFiles) =>
                        acceptedFiles.reduce(
                            (acc, file) => ({
                                ...acc,
                                [file.name]: {
                                    file,
                                    fileType: ""
                                }
                            }),
                            prevFiles
                        )
                    );
                }},
        );

    const files = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path}
            - {file.size} bytes
        </li>
    ));
    return (
        <div {...getRootProps({ className: "dropZone" })}>
            <input name='file' className="input-zone" {...getInputProps()} />
            <CloudUploadIcon sx={{fontSize: 100}}/>
            <p className="dropZone-text">
                Drop file here or click to upload
            </p>
            <em>(Only *.jpeg and *.png images and *.pdf document will be accepted)</em>
            {/*<div className="text-center">
                <p className="dropzone-content">
                    Drag’n’drop some files here, or click to select files
                </p>
            </div>*/}
            <aside>
                <ul>{files}</ul>
            </aside>
        </div>
    );
}
export default Dropzone;
